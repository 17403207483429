import React, { Component, Fragment } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Highlightable from "../components/highlightable";
import AcceptedPaymentMethods from "../components/acceptedPaymentMethods";
import CartHero from "../components/cart/cart-hero";
import CartFooter from "../components/cart/cart-footer";
import CartProductRow from "../components/cart/cart-product-row";
import CartSubscriptionsTotal from "../components/cart/cart-subscriptions-total";
import CartDiscountCode from "../components/cart/cart-discount-code";
import CartTotal from "../components/cart/cart-total";
import CartEmpty from "../components/cart/cart-empty";
import { readLocalCart, cartAmountToggle, removeFromCart } from "../lib/cart";
import { deleteLocalCouponCode, isValidCoupon, readLocalCouponCode, saveLocalCouponCode } from "../lib/coupon";
import "./cart.scss";
import FacebookPixel from "../lib/tracking/facebook-pixel";

class CartPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      products: [],
      coupon: null
    };
    this._cartDiscountCode = React.createRef();
  }

  componentDidMount () {
    const cartProducts = readLocalCart();
    this.setState({ products: cartProducts }, () => {
      return this.loadLocalCoupon(); // promise
    });
  }

  loadLocalCoupon = async () => {
    let couponCode = readLocalCouponCode();
    if (couponCode) {
      const { valid, coupon } = await isValidCoupon(couponCode);
      if (valid) {
        this.setState({ coupon });
        this._cartDiscountCode.current.setState({ discountCode: coupon.code, expanded: true, coupon });
      } else {
        deleteLocalCouponCode();
      }
    }
  };

  handleRemoveFromCart = (productToRemove) => {
    const newCart = removeFromCart(productToRemove.id);
    this.setState({ products: newCart });
  };

  handleAmountToggle = (sign, product) => {
    const localCart = cartAmountToggle(sign, product);
    this.setState({ products: localCart });
  };

  saveCoupon = coupon => {
    this.setState({ coupon });
    if (coupon) {
      saveLocalCouponCode(coupon.code);
    } else {
      deleteLocalCouponCode();
    }
  };

  checkoutButtonClick = () => {
    FacebookPixel.track("InitiateCheckout");
  };

  render () {

    if (this.state.products && this.state.products.length) {

      const subscriptionProducts = this.state.products.filter(p => p.type === "subscription");
      const simpleProducts = this.state.products.filter(p => p.type === "simple");

      return (
        <Layout>
          <SEO title="Your Bag" />
          <Fragment>
            <CartHero products={this.state.products} />
            <section className="section">

              {
                subscriptionProducts.length > 0 &&
                <div className="container cart-container cart-subscriptions">
                  <h3 className="has-text-weight-bold cart-container-title">
                    <Highlightable>Your Monthly Subscription</Highlightable>
                  </h3>
                  {subscriptionProducts.map((product) => (
                    <CartProductRow
                      product={product}
                      key={product.id}
                      handleAmountToggle={this.handleAmountToggle}
                      handleRemoveFromCart={this.handleRemoveFromCart} />
                  ))}
                  <hr className="cart-hr" />
                  <CartSubscriptionsTotal subscriptionProducts={subscriptionProducts} coupon={this.state.coupon}/>
                </div>
              }

              {simpleProducts.length > 0 &&
              <div className="container cart-container cart-simple-products">
                <h3 className="has-text-weight-bold cart-container-title">
                  <Highlightable>One-Time Purchase</Highlightable>
                </h3>
                {simpleProducts.map((product) => (
                  <CartProductRow
                    product={product}
                    key={product.id}
                    handleAmountToggle={this.handleAmountToggle}
                    handleRemoveFromCart={this.handleRemoveFromCart} />
                ))}
                <hr className="cart-hr" />
              </div>}

              <div className="container cart-container">

                <div className="columns">
                  <div className="column is-5">
                    <CartDiscountCode ref={this._cartDiscountCode}
                      couponCallback={this.saveCoupon}
                      cartProducts={this.state.products} />
                  </div>
                  <div className="column is-3 is-offset-4">
                    <CartTotal cartProducts={this.state.products} coupon={this.state.coupon} />
                    <Link to="/checkout">
                      <button onClick={this.checkoutButtonClick} className="button is-primary is-fullwidth">CHECKOUT</button>
                    </Link>
                    <br />
                    <AcceptedPaymentMethods />
                  </div>
                </div>
              </div>
            </section>
            <CartFooter />
          </Fragment>
        </Layout>
      );
    }

    // empty cart
    return (
      <Layout>
        <SEO title="Your Bag" />
        <CartEmpty suggestedProducts={this.props.data.allWcProducts.edges} />
      </Layout>
    );
  }
}

export default CartPage;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWcProducts(filter: {catalog_visibility: {eq: "visible"}, status: {eq: "publish"}, tags: {elemMatch: {name: {eq: "CBD"}}}}) {
      edges {
        node {
          slug
          name
          price
          type
          stock_status
          categories {
            wordpress_id
            name
          }
          acf {
            flavor
            effect
            product_type
          }
          images {
            id
            alt
            src
          }
          grouped_products_nodes {
            id
            wordpress_id
            type
            price
          }
        }
      }
    }
  }
`;
