import React from "react";
import { formatAmount } from "../../lib/helpers";
import { applyCouponToCartProducts } from "../../lib/coupon";

export default function cartSubscriptionsTotal ({ subscriptionProducts, coupon }) {

  let total = subscriptionProducts.reduce((sum, product) => sum + parseFloat(product.price) * product.quantity, 0);
  let saveSum = subscriptionProducts.reduce((sum, product) => sum + parseFloat(product.oneOffPrice) * product.quantity, 0) - total;

  if (coupon && (coupon.discount_type === "recurring_percent" || coupon.discount_type === "universal_recurring_percent")) {
    const priceAfterCoupon = applyCouponToCartProducts(subscriptionProducts, coupon);
    total = priceAfterCoupon.total;
  }

  return (
    <div className="columns">
      <div className="column is-offset-8-desktop">
        <div className="columns is-mobile">
          <div className="column">
            <p>
              Subscription savings<br />
              <strong>Cost per bill</strong>
            </p>
          </div>
          <div className="column has-text-right">
            <p>
              ${formatAmount(saveSum)} / month<br />
              <strong>${formatAmount(total)} / month</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
