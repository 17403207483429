import React from "react";
import PropTypes from "prop-types";

export default function CartTotalRow ({ title, children }) {
  return (
    <div className="level is-mobile">
      <div className="level-left">
        <div className="level-item">
          <h4 className="has-text-weight-bold has-text-left">{title}</h4>
        </div>
      </div>
      <div className="level-right">
        <div className="level-item">
          <h4 className="has-text-weight-bold has-text-right">{children}</h4>
        </div>
      </div>
    </div>
  );
}

CartTotalRow.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}
