import React from "react";
import { isMobile } from "react-device-detect";

export default () => {
  return (
    <footer className="cart-footer has-yellow-background">
      <section className="section cart-footer-section">
        <div className="container">
          <div className="columns is-vcentered is-centered is-variable is-8-desktop">
            <div className={`column is-3 ${!isMobile ?'has-text-centered' : ''}`}>
              <img src={require("../../images/bag/shipping.svg")} style={isMobile ? { marginBottom: "0px", marginRight:"5px" }:{ marginBottom: "0px" }} className="is-inline is-vertical-align" alt="" />&nbsp;&nbsp;
              <h4 className="has-text-white is-inline is-vertical-align">1-3 Day Shipping</h4>
            </div>
            <div className={`column is-4 ${!isMobile ?'has-text-centered' : ''}`}>
              <img src={require("../../images/bag/satisfaction.svg")} style={{ marginBottom: "0px" }} className="is-inline is-vertical-align" alt="" />&nbsp;&nbsp;
              <h4 className="has-text-white is-inline is-vertical-align">100% Satisfaction Guaranteed</h4>
            </div>
            <div className={`column is-3 ${!isMobile ?'has-text-centered' : ''}`}>
              <img src={require("../../images/bag/cancel.svg")} style={isMobile ? { marginBottom: "0px", marginRight: "10px" }: { marginBottom: "0px" }} className="is-inline is-vertical-align" alt="" />&nbsp;&nbsp;
              <h4 className="has-text-white is-inline is-vertical-align">Cancel Anytime</h4>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};
