import React from "react";
import { cartQuantityReducer } from "../../lib/cart";

export default (props) => {
  const totalQuantity = (props.products || []).reduce(cartQuantityReducer, 0);

  return (
    <section className="hero is-medium has-absolute-navbar">
      <div className="hero-body" style={{ paddingBottom: "0px", marginBottom: "0px" }}>
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-6 has-text-centered">
              <h1>Your Bag</h1>
              <h4>{totalQuantity} {totalQuantity === 1 ? "item" : "items"} inside</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
