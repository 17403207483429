import React, { Fragment } from "react";
import { cartTotalReducer } from "../../lib/cart";
import { applyCouponToCartProducts } from "../../lib/coupon";
import CartTotalRow from "./cart-total-row";
import { formatAmount } from "../../lib/helpers";

export default function CartTotal ({ cartProducts = [], coupon = null }) {

  if (coupon) {
    const {
      subtotal, // before discount
      discount_sum,
      discount_percent,
      total // after discount
    } = applyCouponToCartProducts(cartProducts, coupon);

    let discount_text = `-$` + formatAmount(discount_sum);
    if (discount_percent) discount_text += " (" + discount_percent + "%)";

    return (
      <Fragment>
        <CartTotalRow title="Subtotal:">${formatAmount(subtotal)}</CartTotalRow>
        <CartTotalRow title="Discount:"><span className="has-text-danger">{discount_text}</span></CartTotalRow>
        <CartTotalRow title="Total:">${formatAmount(total)}</CartTotalRow>
      </Fragment>
    );
  }

  // no coupon
  const total = cartProducts.reduce(cartTotalReducer, 0);
  return (
    <CartTotalRow title="Total:">${formatAmount(total)}</CartTotalRow>
  );
}
