import React, { Fragment } from "react";
import { Link } from "gatsby";
import ProductCell from "../product/productCell";

export default ({ suggestedProducts }) => {
  return (
    <Fragment>
      <section className="hero is-medium has-absolute-navbar is-relative">
        <img src={require("../../images/cart/frown.svg")} style={{
          position: "absolute",
          top: "30%",
          left: "15%",
          height: "400px",
          zIndex: "-100"
        }} alt="" />
        <div className="hero-body">

          <div className="container">
            <div className="columns is-centered">
              <div className="column is-6 has-text-centered">
                <h1>Oh no! Looks like your bag is empty</h1>
                <br />
                <Link to="/products/cbd-oil-mighty-mango">
                  <button className="select-button is-primary">Continue shopping</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-6 has-text-centered">
              <h2>Suggested just for you</h2>
              <h4>Here are some of our products we know you'll love!</h4>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline is-centered is-mobile">
            {suggestedProducts.filter( ({node}) => node.stock_status === "instock").map(({ node }, index) => (
              <ProductCell product={node} key={index} />
            ))}
          </div>
        </div>
      </section>
    </Fragment>
  );
}
