import React from "react";
import QuantityButton from "../form/quantityButton";
import { renderTags, renderProductNamewithDosage } from "../../utilities/product";

export default function cartProductRow (props) {
  const product = props.product;
  return (
    <div className="columns product-row cart-product-row is-vcentered is-variable is-mobile is-multiline">
      <div className="column is-narrow">
        <figure className="image product-row-image is-square is-100x100">
          {product.images && product.images[0] && product.images[0].src &&
          <img src={product.images[0].src} alt="" />
          }
        </figure>
      </div>
      <div className="column">
        <p className="is-size-4 is-inline is-underlined">{renderProductNamewithDosage(product)}</p>
        <p>{renderTags(product)}</p>
      </div>
      <div className="column is-12-mobile is-6-tablet">
        <div className="columns is-vcentered is-mobile">
          <div className="column is-narrow-mobile is-4-tablet has-text-right-tablet">
            <button className="select-button has-text-dark" onClick={() => props.handleRemoveFromCart(product)}>Remove</button>
          </div>
          <div className="column is-4-tablet has-text-right-mobile">
            <QuantityButton quantity={product.quantity} handleAmountToggle={(amount) => props.handleAmountToggle(amount, product)} />
          </div>
          <div className="column is-4-mobile is-4-tablet">
            <p className="is-size-6 has-text-right">
              {product.type === "subscription"
                          ? `$${product.price} / month`
                          : `$${product.price}`  
                        }  
            </p>
          </div>
        </div>
      </div>
    </div>
  );

};
